export default {
  basics: {
    name: "Matthew Hubbard",
    label: "Senior Software Developer",
    summary:
      "Senior Software developer with 15 years of hands-on development experience in building and maintaining large-scale applications and more recently building an application using .Net Core, Entity Framework with a React UI from the ground up.  With more frontend focused skills my next role would ideally be working with latest Technologies such as React continuously learning and progressing my career.",
    email: "mjhubb@homail.co.uk",
    phone: "07716886529",
    profiles: [
      { username: "MHubbard86", url: "https://github.com/MHubbard86" },
      {
        username: "Matthew Hubbard",
        url: "https://www.linkedin.com/in/mjhubb/",
      },
    ],
  },
  skills: [
    {
      name: "Development",
      skill: [
        { name: "React" },
        { name: "Redux" },
        { name: "Hooks" },
        { name: "TypeScript"},
        { name: "JavaScript" },
        { name: "HTML" },
        { name: "CSS" },
        { name: "jQuery" },
      ],
    },
    {
      name: "Backend Development",
      skill: [
        { name: ".Net Core" },
        { name: "C#" },
        { name: "Entity Framework" },
        { name: "SQL Server" },
        { name: "T-SQL" },
      ],
    },
    {
      name: "Testing Development",
      skill: [
        { name: "React Testing Library" },
        { name: "Jest" },
        { name: "xUnit" },
        { name: "Moq" },        
      ],
    },
    {
      name: "Other",
      skill: [
        { name: "JIRA" },
        { name: "Github" },
        { name: "Agile/SCRUM/Lean" },
        { name: "CI/CD (Team City, Octopus)" },
        { name: "OAuth" },
        { name: "Okta" },
        { name: "VSCode/VS2019/VS2022" },
      ],
    },
  ],
  work: [
    {
      position: "Senior Developer",
      company: "Diligent",
      website: "https://Diligent.com",
      summary: "Designing and Building full stack apps for Company Integrations",
      startDate: "2020",
    },
    {
      position: "Senior Web Developer",
      company: "Diligent Entities",
      website: "https://diligent.com/entity-management-software",
      summary: "Lead developer on Diligent Entities web app",
      startDate: "2018",
      endDate: "2020",
    },
    {
      position: "Web Developer",
      company: "ICSA Software",
      website: "https://Diligent.com",
      summary: "Developer on Blueprint OneWorld web app",
      startDate: "2011",
      endDate: "2018",
    },
    {
      position: "Development Consultant",
      company: "ICSA Software",
      website: "https://Diligent.com",
      summary: "Implementation consultant for the Blueprint OneWorld web app",
      startDate: "2007",
      endDate: "2011",
    },
  ],
  education: [
    {
      institution: "University of Essex",
      website: "https://www.essex.ac.uk/",
      area: "Computer Science",
      studyType: "2:1 in Bachelor of Science (BSc)",
      startDate: "2004",
      endDate: "2007",
    },
  ],
  interests: [
    {
      name: "Health & Fitness",
      keywords: ["Sports", "Running", "Gym", "Football"],
    },
    { name: "Animals", keywords: ["Dogs", "Pomeranians"] },
    { name: "Technology", keywords: ["Programming", "Computers"] },
  ],
};
