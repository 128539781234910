const breakpoints = [1080, 640, 384, 256, 128, 96, 64, 48];

const path = "../images/gallery/hair/";
const photoLink = (id) => `${path}${id}.jpg`;

const Photos = [
    { id: "88", width: 1536, height: 2048 },
    { id: "89", width: 1536, height: 2048 },
    { id: "90", width: 1169, height: 1727 },
    { id: "91", width: 1169, height: 1428 },
    { id: "92", width: 1536, height: 2048 },
    { id: "93", width: 1536, height: 2048 },
    { id: "52", width: 1536, height: 2048 },
    { id: "53", width: 1169, height: 769 },
    { id: "54", width: 1169, height: 1692 },
    { id: "55", width: 1366, height: 2048 },
    { id: "56", width: 1536, height: 2048 },
    { id: "57", width: 1169, height: 1174 },
    { id: "58", width: 1169, height: 1417 },
    { id: "59", width: 556, height: 845 },
    { id: "94", width: 1536, height: 2048 },
    { id: "95", width: 1702, height: 2048 },
    { id: "96", width: 1440, height: 1800 },
    { id: "97", width: 1087, height: 1315 },
    { id: "98", width: 719, height: 1135 },
    { id: "99", width: 1536, height: 2048 },
    { id: "100", width: 1536, height: 2048 },
    { id: "101", width: 1170, height: 1108 },
    { id: "102", width: 1638, height: 2048 },
    { id: "21", width: 1024, height: 683 },
    { id: "22", width: 1536, height: 2048 },
    { id: "23", width: 1536, height: 2048 },
    { id: "24", width: 1536, height: 2048 },
    { id: "25", width: 748, height: 525 },
    { id: "26", width: 750, height: 939 },
    { id: "27", width: 750, height: 721 },
    { id: "28", width: 1640, height: 2048 },
    { id: "29", width: 1170, height: 1463 },
    { id: "30", width: 1169, height: 1409 },
    { id: "31", width: 1280, height: 1600 },
    { id: "32", width: 1536, height: 2048 },
    { id: "33", width: 1992, height: 2048 },
    { id: "34", width: 1280, height: 1600 },
    { id: "35", width: 1280, height: 1600 },
    // { id: "36", width: 1280, height: 1600 },
    { id: "37", width: 1169, height: 1461 },
    { id: "38", width: 767, height: 1142 },
    { id: "39", width: 884, height: 809 },
    { id: "40", width: 750, height: 683 },
    { id: "41", width: 1536, height: 2048 },
    { id: "42", width: 1170, height: 1253 },
    { id: "43", width: 1638, height: 2048 },
    { id: "44", width: 1170, height: 738 },
    { id: "45", width: 1170, height: 738 },
    { id: "46", width: 960, height: 960 },
    { id: "47", width: 1042, height: 1021 },
    { id: "48", width: 1169, height: 858 },
    { id: "49", width: 1638, height: 2048 },
    { id: "50", width: 1638, height: 2048 },
    { id: "51", width: 1638, height: 2048 },
    { id: "60", width: 1169, height: 1415 },
    { id: "61", width: 2048, height: 1366 },
    { id: "62", width: 1536, height: 2048 },
    { id: "63", width: 1132, height: 1175 },
    { id: "64", width: 1536, height: 2048 },
    { id: "65", width: 1169, height: 840 },
    { id: "66", width: 1169, height: 1987 },
    { id: "67", width: 1632, height: 2048 },
    { id: "68", width: 1536, height: 2048 },
    { id: "69", width: 2048, height: 1366 },
    { id: "70", width: 1170, height: 1841 },
    { id: "71", width: 1169, height: 726 },
    { id: "72", width: 1169, height: 1698 },
    { id: "73", width: 1169, height: 744 },
    { id: "74", width: 1169, height: 738 },
    { id: "75", width: 1169, height: 1339 },
    { id: "76", width: 1440, height: 1800 },
    { id: "77", width: 1536, height: 2048 },
    { id: "78", width: 804, height: 1201 },
    { id: "79", width: 753, height: 1147 },
    { id: "80", width: 1169, height: 1430 },
    { id: "81", width: 1638, height: 2048 },
    { id: "82", width: 1536, height: 2048 },
    { id: "83", width: 1168, height: 1401 },
    { id: "84", width: 1170, height: 1378 },
    { id: "85", width: 1536, height: 2048 },
    { id: "86", width: 1536, height: 2048 },
    { id: "87", width: 1536, height: 2048 },
    { id: "1", width: 750, height: 557 },
    { id: "2", width: 750, height: 901 },
    { id: "3", width: 739, height: 867 },
    { id: "4", width: 739, height: 694 },
    { id: "5", width: 576, height: 568 },
    { id: "6", width: 750, height: 851 },
    { id: "7", width: 750, height: 877 },
    { id: "8", width: 498, height: 585 },
    { id: "9", width: 1280, height: 1600 },
    { id: "10", width: 750, height: 680 },
    { id: "11", width: 1200, height: 1600 },
    { id: "12", width: 750, height: 914 },
    { id: "13", width: 720, height: 900 },
    { id: "14", width: 750, height: 981 },
    { id: "15", width: 750, height: 894 },
    { id: "16", width: 745, height: 974 },
    { id: "17", width: 1536, height: 2048 },
    { id: "18", width: 1536, height: 2048 },
    { id: "19", width: 691, height: 1024 },
    { id: "20", width: 720, height: 960 },
];

const hairPhotos = Photos.map((photo) => ({
    src: photoLink(photo.id),
    width: photo.width,
    height: photo.height,
    images: breakpoints.map((breakpoint) => {
        const height = Math.round((photo.height / photo.width) * breakpoint);
        return {
            src: photoLink(photo.id),
            width: breakpoint,
            height,
        };
    }),
}));

export default hairPhotos;