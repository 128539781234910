const Data = [
  {
    question: "What happens at my hair trial?",
    answer: "At hair trial stage, which takes place 2-3 months before your day.  "
    + "This is an opportunity to get to know each other and go through potential hair style options and accessories.  "
    + "Each hair appointment normally takes around 2-3 hours.  We will also go through arrivals and timings of the morning and build a schedule.",
  },
  {
    question: "Cancellation Policy",
    answer: "Once your booking fee has been made this becomes non-refundable.  A booking fee is taking to secure your date so another couple cannot book it and also due to high demand especially summer dates.  "
    + "Once the full payment has been made this also becomes non-refundable.  In the event I need to cancel your date for unforeseen circumstances a full refund of deposit or full amount will be made.",
  },
  {
    question: "I have very specific requirements can these be catered for?",
    answer: "I always do my up most fulfil my couples wants and needs.  Sometimes not every need can be "
    + "fulfilled but I will do my best I can to help.",
  },
  {
    question: "How do I prep my hair for the day?",
    answer: "I do request that all hair is clean and dry on the morning of the wedding.  "
    + "The day or evening before is ideal for hair washing, if hair is washed or wet on morning this can affect "
    + "the overall look, timings and effect how the styles can be created.",
  },
  {
    question: "How do I book?",
    answer: "After your enquiry form has been filled out and your date has been confirmed, you can secure your "
    + "date by paying a £50 booking fee.  Booking fees are non-fundable, but in the event that I need to cancel your date "
    + "due to unforeseen circumstances your fee will be refunded in full."
  },
  {
    question: "How long in advance can I book?",
    answer: "My diary is open through 2023 and 2024."
  },
  {
    question: "How do payments work?",
    answer: "I normally take full payments up to a week before the wedding day, however you can make early payments "
    + "if you want to.  But I can only accept full payments 2-3 months before your date.  You can also pay in instalments if you wish to."
  }
];

export default Data;
