const path = "../videos/reels/";
const videos = [
    //{ src: path + "23_1.mp4"},
    { src: path + "24_1.mp4"},
    { src: path + "24_2.mp4"},
    { src: path + "23_2.mp4"},
    { src: path + "23_3.mp4"},
    { src: path + "23_4.mp4"},
    //{ src: path + "23_5.mp4"},
    { src: path + "23_6.mp4"},
    { src: path + "23_7.mp4"},
    // { src: path + "23_8.mp4"},
    { src: path + "1.mp4"},
    { src: path + "2.mp4"},
    { src: path + "3.mp4"},
    { src: path + "4.mp4"},
    { src: path + "5.mp4"},
];

export default videos;