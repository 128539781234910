import React from "react";
import PageHeader, { PageParagraph } from '../../components/Headers/Pageheaders';
import HairService from './Hair/Hair';
import Enquire from '../Buttons/Enquire';
import './styles.css';

export default function Services() {
  const PageDescription = `Specialists in the modern Boho Bride and Bridal hair styling with experience spamming over 20 years.`
  return (
    <>
      <section>
        <PageHeader title="Services"/>      
        <PageParagraph text={PageDescription} endIcon={<Enquire isFilled={true} />} />
        <div className="container-fluid">
          <div className="service-grid justify-content-center">
            <HairService />
          </div>
        </div>   
      </section>      
    </>
  );
}
